<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('employees.form.createEmployees')"
          :description="
            $t('employees.form.FromHereYouCanCreateAnewEmployees')
          "
          v-if="$route.name == 'employeesCreate'"
        />
        <addHeader
          :title="$t('employees.form.editEmployees')"
          :description="
            $t('form.fromHereYouCanModifyTheEmployeesData')
          "
          v-if="$route.name == 'employeesEdit'"
        />

        <div class="mb-4 row">
          <dvider
            :title="$t('employees.form.basicInformation')"
            :description="$t('employees.form.EmployeesBasicInformation')"
            noLine="true"
          />
          <fileInput :title="$t('employees.form.employeeImage')"
            v-model="item.image"
            :image="item.image"
            :path="'employees'"
            :hasErorr="errors.image"
            :error="$t('allerts.ChooseASuitableFile')"
            :col="'col-12'"
          />
          <formInput
            :title="$t('employees.form.EmployeesName')"
            v-model="item.name"
            :hasErorr="errors.name"
            :error="$t('allerts.thisFieldIsRequired')"
            maxlength="255"
          />
          <formInput
            :title="$t('employees.form.JobTitle')"
            v-model="item.job_title"
            maxlength="255"
          />
          <monyInput
            :title="$t('employees.form.salary')"
            v-model="item.salary"
          />
          <monyInput
            :title="$t('employees.form.balance')"
            v-model="item.balance"
            :disabled="this.$route.name == 'employeesEdit' ? true : false"
          />
          <formInput
            :title="$t('employees.form.age')"
            v-model="item.age"
            maxlength="255"
          />

          <connectionInput
            :title="$t('employees.form.User')"
            :group="$t('employees.form.Users')"
            v-model="item.user_id"
            :hasAdd="false"
            :values="users"
          />

          <iconInput
            :type="'number'"
            :title="$t('employees.form.SalesRatio')"
            :icon="'percentage'"
            v-model="item.rate"
          />

          <subFileInput :title="$t('invoices.form.invoicesbalance')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
          />

          <checkInput :title="$t('employees.form.employeeStatus')" :value="item.stat" v-model="item.stat"/>
          
          <dvider
            :title="$t('employees.form.contactInformation')"
            :description="$t('employees.form.customerContactInformation')"
          />
          <iconInput
            :type="'text'"
            :title="$t('employees.form.telephoneNumber')"
            :icon="'phone-office'"
            v-model="item.telephone"
          />
          <iconInput
            :type="'text'"
            :title="$t('employees.form.faxNumber')"
            :icon="'fax'"
            v-model="item.fax"
          />
          <iconInput
            :type="'text'"
            :title="$t('employees.form.mobileNumber')"
            :icon="'phone'"
            v-model="item.mobile"
          />
          <iconInput
            :type="'text'"
            :title="$t('employees.form.theSiteIsOnTheInternet')"
            :icon="'browser'"
            v-model="item.website"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'employeesCreate' && !$parent.stopEdit"
          >
            {{ $t('employees.form.createEmployees') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'employeesEdit' && !$parent.stopEdit"
          >
            {{ $t('employees.form.editEmployees') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import fileInput from '../elements/add/form/fileInput.vue'
import formInput from "../elements/add/form/formInput.vue";
import iconInput from "../elements/add/form/iconInput.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import checkInput from '../elements/add/form/checkInput.vue'
import subFileInput from "../elements/add/form/subFileInput.vue";
export default {
  data() {
    return {
      path: "/employees",
      item: {
        user_id: 0,
      },
      errors: {
        name: false,
      },
      users: {},
    };
  },
  methods: {
    getUsers() {
      axios.get(this.$linkGnirator("/usersItems")).then((response) => {
        this.users = response.data;
      });
    },
    saveItem() {
      var error = 0;
      if (typeof this.item.name === "undefined" || this.item.name == "") {
        error = 1;
        this.errors.name = true;
      }
      if (error == 0) {
        if (this.$route.name == "employeesCreate") {
          this.$parent.aletText =
            this.$t('allerts.employeesuccessfullyAdded');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          this.$router.go(-1);
        } else if (this.$route.name == "employeesEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "emailError") {
                this.$parent.aletText =
                  this.$t('allerts.emailIsAlreadyInUse');
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText =
                  this.t('allerts.userHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                if (this.item.id == this.$user.id) {
                  this.$parent.getCompanies();
                }
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    getItem() {
      var item = this.$database.employees.find((el) => el.id == this.$route.params.id);
      if (item) {this.item = item;}
      this.$parent.stopEdit = false;
    }
  },
  mounted() {
    if (this.$route.name == "employeesEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }
    this.getUsers();

    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  components: {
    formInput,
    dvider,
    fileInput,
    addHeader,
    iconInput,
    loading,
    monyInput,
    connectionInput,
    checkInput,
    subFileInput
  },
};
</script>
